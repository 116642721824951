/* eslint-disable import/prefer-default-export */
import toolsGrayIcon from 'images/tools-lightgray.svg';
import toolsBlackIcon from 'images/tools-black.svg';

export const createToolsBreadcrumbs = (pageTitle, isDark = false) => {
  return {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Tools',
        icon: isDark ? toolsBlackIcon : toolsGrayIcon,
        url: '/tools',
      },
      {
        title: pageTitle,
        icon: null,
        url: null,
        hideInMobile: true,
      },
    ],
    theme: isDark ? 'dark' : 'darHeroHeader',
    absolute: true,
    transparent: true,
  };
};
