import React from 'react';
import StyledRichText from 'components/RichText/StyledRichText';
import { DescriptionContainer } from '../styles';

const DescriptionBlock = ({ data }) => {
  return (
    <DescriptionContainer id="section-tool-page-content--container">
      {data && <StyledRichText content={data} />}
    </DescriptionContainer>
  );
};

export default DescriptionBlock;
