import styled from 'styled-components';
import Modal from 'components/Core/Modal';

export const LaborRateCalcFormContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 62px;
  background: var(--white);
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
  margin: 0 auto 100px;

  .labor-rate-calc-home-form--container {
    display: flex;

    .labor-rate-calc-home-form--left-side {
      flex: auto;

      .labor-rate-calc-home-form--form {
        margin: 0 auto;

        .labor-rate-calc-home-form--form-item {
          margin-bottom: 35px;

          .labor-rate-calc-home-form--other-industry {
            margin-top: 15px;
          }
        }

        .labor-rate-calc-home-form--footer-description {
          max-width: 466px;
          font-weight: var(--font-weight-500);
          font-size: var(--font-size-13);
          line-height: var(--line-height-150);
          text-align: center;
          color: var(--grey-6);
          margin: 0 auto;
        }
      }
    }

    .labor-rate-calc-home-form--right-side {
      width: 348px;
      padding: 0 30px 0 80px;
      display: flex;
      align-items: center;

      .labor-rate-calc-home-form--man-image {
        max-width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 30px 24px;

    .labor-rate-calc-home-form--container {
      .labor-rate-calc-home-form--left-side {
        .labor-rate-calc-home-form--form {
          .labor-rate-calc-home-form--form-item {
            margin-bottom: 24px;
          }

          .labor-rate-calc-home-form--submit-button {
            line-height: 31px;
            padding: 12px 24px;
          }
        }
      }

      .labor-rate-calc-home-form--right-side {
        display: none;
      }
    }
  }

  @media (max-width: 575px) {
    padding: 20px 24px;
  }
`;

export const LRCSteps = styled.div`
  text-align: center;
  max-width: 900px;
  margin: 60px auto 0;
  padding: 0 28px;

  .lrc-step--field-question-control {
    margin-bottom: 10px;
  }

  .lrc-step--field-question {
    margin-bottom: 0;

    .form-field--label-container {
      justify-content: center;
    }
  }

  .lrc-step--field-description {
    text-align: center;
    max-width: 627px;
    margin: 0 auto 20px;

    &.calc-description {
      font-size: var(--font-size-25);
      line-height: var(--line-height-120);
      color: var(--darkest);
    }
  }

  .lrc-step--field-text {
    max-width: 486px;
    margin: 0 auto 40px;

    .text-field-helper {
      display: none !important;
    }
  }

  .lrc-step--field-select {
    max-width: 486px;
    margin: 0 auto 40px;
  }

  .lrc-step--field-img {
    &.calc-gif {
      width: 307px;
      max-width: calc(100% - 40px);
      margin-bottom: 20px;
      margin-top: -30px;
    }
  }

  .lrc-step--button-group {
    margin-top: 20px;

    button {
      margin: 0 20px;
      min-width: 134px;
      display: inline-block;
    }

    .lrc-step--button-back {
      color: var(--grey-8);
    }
  }

  @media (max-width: 767px) {
  }

  @media (max-width: 575px) {
    margin-top: 44px;
    padding: 0 20px;

    .lrc-step--button-group {
      button {
        margin: 0 10px;
      }
    }
  }
`;

export const ResultModal = styled(Modal)`
  .calc-result-modal {
    background: white;
    border-radius: 2px;
    text-align: center;
    padding: 40px;
  }

  .lrc-step--field-description {
    text-align: center;
    margin: 0 auto;

    &.calc-result-title {
      text-align: center;
      margin-bottom: 36px;
    }

    &.calc-result-description {
      color: var(--darkest);
    }
  }

  .lrc-step--field-text {
    margin: 20px auto;

    .text-field-helper {
      display: none !important;
    }
  }

  .lrc-step--field-img {
    &.calc-result-img {
      margin-bottom: 27px;
      width: 207px;
      max-width: 100%;
    }
  }

  .lrc-step--button-next {
    width: 100%;
  }
`;

export const ResultsHeroContainer = styled.div`
  background: white;
  padding-bottom: 50px;
  margin-top: 50px;
  .section-lrc-results-hero--wrapper {
  }

  .social-sharing-container {
    display: flex !important;
  }

  h2 {
    margin: 0;
  }

  .section-lrc-results-hero--container {
    max-width: 1252px;
    margin: 0 auto;
    padding: 60px 28px 0;

    .section-lrc-results-hero--first-section {
      display: flex;

      .section-lrc-results-hero--first-left-side {
        flex: auto;
        padding: 0 38px 0 0;

        .section-lrc-results-hero--results-container {
          background: ${(props) => 'url(' + props.backgroundImg + ')'};
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 250px -280px;
          padding: 30px 60px 60px 30px;
          border: 1px solid var(--grey-4);
          height: 100%;

          .section-lrc-results-hero--results-row {
            display: flex;
            align-items: center;
            margin-top: 30px;

            .section-lrc-results-hero--results-label {
              flex: 4;
              font-weight: var(--font-weight-700);
              font-size: var(--font-size-20);
              line-height: var(--line-height-120);
              text-align: right;
              letter-spacing: var(--letter-spacing--0-01);
              margin-right: 38px;
            }

            .section-lrc-results-hero--results-value {
              flex: 6;
              font-weight: var(--font-weight-500);
              font-size: var(--font-size-18);
              line-height: 23px;
              color: var(--darkest);
              padding: 18px 23px;
              background: white;
              border: 1px solid var(--grey-4);
              margin: 0;
            }
          }
        }
      }

      .section-lrc-results-hero--first-right-side {
        .section-lrc-results-hero--demo-container {
          max-width: 372px;
          padding: 36px 33px;
          background: white;
          border: 1px solid var(--grey-4);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .section-lrc-results-hero--demo-title {
            text-align: center;
          }

          .section-lrc-results-hero--demo-description {
            text-align: center;
            margin-top: 31px;
            margin-bottom: 0;
          }

          .section-lrc-results-hero--get-demo-btn {
            font-size: var(--font-size-20);
            width: 100%;
            height: 62px;
            margin-top: 46px;
          }
        }
      }
    }

    .section-lrc-results-hero--second-section {
      display: flex;
      background: transparent;
      align-items: center;
      justify-content: center;
      padding: 76px 39px 60px;

      .section-lrc-results-hero--share-title {
        text-align: right;
        margin-right: 24px;
        min-width: 197px;
      }

      .section-lrc-results-hero--share-container {
        height: 100px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        .section-lrc-results-hero--share-input-container {
          width: 100%;
          max-width: 526px;
          height: 62px;
          display: flex;
          padding: 0;

          & > div:first-child {
            width: 100%;
            button {
              min-width: 107px;
              margin-left: 0;
            }
          }
        }

        .section-lrc-results-hero--share-splitter {
          width: 1px;
          height: 100%;
          border-left: 1px solid var(--dark);
          margin: 0 40px;
          opacity: 0.5;
        }

        .section-lrc-results-hero--share-copy-btn {
          height: 48px;
          border: 1px solid var(--dark);
          background: transparent;
          font-weight: var(--font-weight-700);
          font-size: var(--font-size-16);
          line-height: var(--line-height-110);
          text-align: center;
          color: var(--dark);
          padding: 15px 24px;
          min-width: 121px;

          &:hover {
            background: #00000022;
          }
        }
      }
    }

    .section-lrc-results-hero--third-section {
      padding: 30px;
      border: 1px solid var(--grey-4);

      .section-lrc-results-hero--recalc-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .section-lrc-results-hero--recalc-title {
          font-size: var(--font-size-25);
        }

        .section-lrc-results-hero--retake-button {
          font-weight: var(--font-weight-700);
          font-size: var(--font-size-20);
          line-height: var(--line-height-110);
          letter-spacing: var(--letter-spacing--0-01);
          margin: 0;
          display: flex;
          cursor: pointer;
          color: var(--darkest);

          svg {
            margin-right: 10px;
          }
        }
      }

      .section-lrc-results-hero--recalc-form {
        display: flex;
        margin-top: 30px;

        .section-lrc-results-hero--recalc-column {
          flex: 1;
          padding: 0 20px;

          .section-lrc-results-hero--recalc-item-row {
            margin-bottom: 30px;

            .lrc-step--field-question {
              margin-bottom: 10px;
            }

            .lrc-step--field-text {
              .text-field-input {
                font-size: var(--font-size-18) !important;
              }

              .text-field-helper {
                display: none !important;
              }
            }

            .lrc-step--field-select {
              margin: 0 auto;
            }

            .lrc-step--button-recalc {
              margin-top: 33px;
              height: 58px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1136px) {
    .section-lrc-results-hero--container {
      .section-lrc-results-hero--first-section {
        .section-lrc-results-hero--first-left-side {
          .section-lrc-results-hero--results-container {
            padding: 30px;
            background-position: right -150px;

            .section-lrc-results-hero--results-row {
              .section-lrc-results-hero--results-label {
                margin-right: 18px;
              }
            }
          }
        }
      }

      .section-lrc-results-hero--third-section {
        .section-lrc-results-hero--recalc-form {
          flex-wrap: wrap;

          .section-lrc-results-hero--recalc-column {
            flex: none;
            width: 50%;

            &:last-child {
              width: 100%;
              display: flex;

              .section-lrc-results-hero--recalc-item-row {
                flex: 1;

                &:first-child {
                  margin-right: 20px;
                }
                &:last-child {
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .section-lrc-results-hero--container {
      .section-lrc-results-hero--first-section {
        .section-lrc-results-hero--first-left-side {
          padding: 0 18px 0 0;

          .section-lrc-results-hero--results-container {
            .section-lrc-results-hero--results-row {
              .section-lrc-results-hero--results-label {
                flex: 5;
                font-size: var(--font-size-18);
              }

              .section-lrc-results-hero--results-value {
                flex: 5;
              }
            }
          }
        }
        .section-lrc-results-hero--first-right-side {
          .section-lrc-results-hero--demo-container {
            max-width: 340px;
          }
        }
      }

      .section-lrc-results-hero--second-section {
        .section-lrc-results-hero--share-title {
          text-align: left;
        }

        .section-lrc-results-hero--share-container {
          .section-lrc-results-hero--share-input-container {
            max-width: 100%;
          }
        }
      }

      .section-lrc-results-hero--third-section {
        .section-lrc-results-hero--recalc-form {
          .section-lrc-results-hero--recalc-column {
            padding: 0 10px;

            &:last-child {
              .section-lrc-results-hero--recalc-item-row {
                &:first-child {
                  margin-right: 10px;
                }
                &:last-child {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-lrc-results-hero--container {
      padding-left: 20px;
      padding-right: 20px;

      .section-lrc-results-hero--first-section {
        flex-direction: column;

        .section-lrc-results-hero--first-left-side {
          padding: 0;
          margin-bottom: 30px;

          .section-lrc-results-hero--results-container {
            .section-lrc-results-hero--results-row {
              .section-lrc-results-hero--results-label {
                flex: 4;
              }

              .section-lrc-results-hero--results-value {
                flex: 6;
              }
            }
          }
        }
        .section-lrc-results-hero--first-right-side {
          .section-lrc-results-hero--demo-container {
            max-width: 500px;
            margin: 0 auto;
          }
        }
      }

      .section-lrc-results-hero--second-section {
        flex-direction: column;
        align-items: center;
        padding: 32px 0;

        .section-lrc-results-hero--share-title {
          font-size: var(--font-size-20);
          letter-spacing: var(--letter-spacing--0-01);
          text-align: center;
          margin: 0;
        }

        .section-lrc-results-hero--share-container {
          flex-direction: column;
          height: auto;
          margin-top: 10px;

          .section-lrc-results-hero--share-splitter {
            width: 100%;
            height: 1px;
            border-left: none;
            border-top: 1px solid var(--dark);
            margin: 10px 0 12px;
          }

          .section-lrc-results-hero--share-copy-btn {
            width: 100%;
          }
        }
      }

      .section-lrc-results-hero--third-section {
        padding: 30px 30px 0;

        .section-lrc-results-hero--recalc-form {
          .section-lrc-results-hero--recalc-column {
            width: 100%;

            .section-lrc-results-hero--recalc-item-row {
              .lrc-step--field-select {
                margin: 0 auto;
              }
            }

            &:last-child {
              width: 100%;
              flex-direction: column;

              .section-lrc-results-hero--recalc-item-row {
                flex: none;

                &:first-child {
                  margin-right: 0;
                }
                &:last-child {
                  margin-left: 0;
                }

                .lrc-step--button-recalc {
                  margin-top: 0;
                  height: 48px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-lrc-results-hero--container {
      .section-lrc-results-hero--first-section {
        .section-lrc-results-hero--first-left-side {
          .section-lrc-results-hero--results-container {
            padding: 20px;
          }
        }

        .section-lrc-results-hero--first-right-side {
          .section-lrc-results-hero--demo-container {
            padding: 20px;
          }
        }
      }

      .section-lrc-results-hero--third-section {
        padding: 20px 20px 0;

        .section-lrc-results-hero--recalc-form {
          .section-lrc-results-hero--recalc-column {
            padding: 0;

            .section-lrc-results-hero--recalc-item-row {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
`;
