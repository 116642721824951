import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { focusOptions, typeOfWorkOptions } from 'components/Form/utils/generalFormData';
import useIndustries from 'hooks/useIndustries';
import TextField from 'components/Core/TextField';
import Select from 'components/Core/Select';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import useLaborRateCalcImages from 'hooks/useLaborRateCalcImages';
import { getFormValid } from 'utils/formUtils';
import { FormContainer } from '../../styles';
import { LaborRateCalcFormContainer } from '../styles';

const LaborRateCalcHomeForm = ({ formValues, onClickNext, ...otherProps }) => {
  const { industryList, otherIndustryList } = useIndustries();
  otherIndustryList.unshift({ label: 'Select from other industries...', value: '' });
  industryList.unshift({ label: 'Select an industry...', value: '' });
  const pageImages = useLaborRateCalcImages();
  const manImage =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'LaborRateCalc Home')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  const formik = useFormik({
    initialValues: {
      industry: formValues.industry || '',
      otherIndustry: formValues.otherIndustry || '',
      businessFocus: formValues.businessFocus || '',
      areaOfFocus: formValues.areaOfFocus || '',
      company: formValues.company || '',
    },
    validationSchema: Yup.object().shape({
      industry: Yup.string().required('Industry is invalid'),
      otherIndustry: Yup.string().when('industry', {
        is: 'Other',
        then: Yup.string().required('Other Industry is invalid'),
      }),
      businessFocus: Yup.string().required('Business Focus is invalid'),
      areaOfFocus: Yup.string().required('Area of Focus is invalid'),
      company: Yup.string().trim().required('Company is invalid'),
    }),
    onSubmit: (values) => {
      onClickNext(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const formValid = getFormValid({ ...formik });

  return (
    <FormContainer {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <LaborRateCalcFormContainer {...otherProps}>
            <div className="labor-rate-calc-home-form--container">
              <div className="labor-rate-calc-home-form--left-side">
                <div
                  data-position="Labor Rate Calculator Form"
                  className="labor-rate-calc-home-form--form"
                >
                  <FormLabelWithField
                    formControlProps={{ className: 'labor-rate-calc-home-form--form-item' }}
                    label="What industry do you do the most business in?"
                    isRequired={false}
                  >
                    <Select
                      id="industry"
                      placeholder="Select Industry"
                      options={industryList}
                      formValid={formValid}
                      handleChange={onHandleChange}
                      value={formik.values.industry}
                    />

                    {formik.values.industry === 'Other' && (
                      <Select
                        id="otherIndustry"
                        className="labor-rate-calc-home-form--other-industry"
                        placeholder="Select Other Industry"
                        options={otherIndustryList}
                        formValid={formValid}
                        handleChange={onHandleChange}
                        value={formik.values.otherIndustry}
                      />
                    )}
                  </FormLabelWithField>
                  <FormLabelWithField
                    formControlProps={{ className: 'labor-rate-calc-home-form--form-item' }}
                    label="Business Focus"
                    isRequired={false}
                  >
                    <Select
                      id="businessFocus"
                      placeholder="Select Focus"
                      options={[{ label: 'Select Focus', value: '' }, ...focusOptions]}
                      formValid={formValid}
                      handleChange={onHandleChange}
                      value={formik.values.businessFocus}
                    />
                  </FormLabelWithField>
                  <FormLabelWithField
                    formControlProps={{ className: 'labor-rate-calc-home-form--form-item' }}
                    label="Area of Focus"
                    isRequired={false}
                  >
                    <Select
                      id="areaOfFocus"
                      placeholder="Select Area of Focus"
                      options={[{ label: 'Select Area of Focus', value: '' }, ...typeOfWorkOptions]}
                      formValid={formValid}
                      handleChange={onHandleChange}
                      value={formik.values.areaOfFocus}
                    />
                  </FormLabelWithField>
                  <FormLabelWithField
                    formControlProps={{ className: 'labor-rate-calc-home-form--form-item' }}
                    label="Company Name"
                    isRequired={false}
                  >
                    <TextField
                      className="bc-step--field-text"
                      id="company"
                      type="text"
                      placeholderText="Enter Company Name"
                      values={formik.values}
                      formValid={formValid}
                      handleChange={onHandleChange}
                      showOnlyFormError
                    />
                  </FormLabelWithField>
                  <Button
                    data-position="Calculator - CTA"
                    className="labor-rate-calc-home-form--submit-button labor-rate-calc-home-form--form-item"
                    type="primary"
                    width="100%"
                    shadow={false}
                    onClick={() => formik.submitForm()}
                  >
                    Get Started
                  </Button>
                  <div className="labor-rate-calc-home-form--footer-description">
                    This interactive calculator is made available to you as a self-help tool for
                    your independent use and is not intended to provide financial advice.
                  </div>
                </div>
              </div>
              <div className="labor-rate-calc-home-form--right-side">
                <img src={manImage} className="labor-rate-calc-home-form--man-image" alt="img" />
              </div>
            </div>
          </LaborRateCalcFormContainer>
        </div>
      </div>
    </FormContainer>
  );
};

const LRCHomeForm = LaborRateCalcHomeForm;
export default LRCHomeForm;
