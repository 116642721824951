import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import LRCHomeForm from 'components/Tools/LaborRateCalculator/HomeForm';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import { splashCheck } from 'utils/urlHelper';
import BookmarkContext from 'utils/BookmarkContext';
import { getSetCookies } from 'utils/cookieUtility';
import { createToolsBreadcrumbs } from '../utils/createToolsBreadcrumbs';

const LaborRateCalcPage = (props) => {
  const title = 'Labor Rate Calculator';
  const data = props.data.contentfulTool;
  const breadcrumbs = createToolsBreadcrumbs(title);

  const [values, setValues] = useState({
    industry: '',
    otherIndustry: '',
    businessFocus: '',
    areaOfFocus: '',
    company: '',
  });

  const convertParams = (formValues) => {
    return (
      `industry=${formValues.industry}&otherIndustry=${formValues.otherIndustry}&businessFocus=` +
      `${formValues.businessFocus} ${encodeURIComponent(formValues.areaOfFocus)}` +
      `&company=${formValues.company}`
    );
  };

  const onClickNext = (formValues) => {
    navigate(splashCheck(`${props.path}/overhead?`) + convertParams(formValues));
  };

  useEffect(() => {
    const tempValues = getSetCookies(values);
    setValues(tempValues);
  }, []);

  const bookmarkData = {
    title: data.internalName,
    image: data?.cardImage?.image,
    subTitle: 'Tool',
    url: props.path,
    type: 'tool',
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
        <Hero data={data.hero} />
        <LRCHomeForm onClickNext={onClickNext} formValues={values} />
        {data.description && <DescriptionBlock data={data.description} />}
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default LaborRateCalcPage;

export const LaborRateCalcPageQuery = graphql`
  query LaborRateCalcPageQuery($internalName: String!) {
    contentfulTool(internalName: { eq: $internalName }) {
      ...Tool
    }
  }
`;
